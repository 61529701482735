import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, withPrefix } from "gatsby"
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl";

const AboutPage = ({ data,intl }) =>  (
        <Layout>
            <SEO title={intl.formatMessage({ id:'About' })} />
            <section>
                        {/*<BackgroundImage Tag="section"
                            fluid={data.placeholderImage.childImageSharp.fluid}
                            className="page-top-section"
                        >
                        </BackgroundImage>*/}

                        {/* OUR AGENTS SECTION */}
                        <section className="team-section">
                            <div className="container">
                                <div className="mainTitle">
                                    <h2>{intl.formatMessage({ id:'Our-agents'})}</h2>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-sm-6 col-12">
                                        <div className="team-member">
                                            <div className="member-pic">
                                                <Img fluid={data.Connie.childImageSharp.fluid} />
                                            </div>
                                            <div className="member-info">
                                                <h5>Connie Slootjes</h5>
                                                <span>{intl.formatMessage({ id:'ManagingDirector' })}</span><br></br>
                                                <span>{intl.formatMessage({ id:'Founder' })}</span>
                                                <div className="member-contact">
                                                    <p><i className="fa fa-phone"></i>+34-922794214</p>
                                                    <p><i className="fa fa-envelope"></i>info@visaverde.com</p>
                                                    <div className="row inlineFlags">
                                                        <Img  className="col-2" fluid={data.lng_nl.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_es.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_en.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_de.childImageSharp.fluid} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-12">
                                        <div className="team-member">
                                            <div className="member-pic">
                                                <Img fluid={data.Katerina.childImageSharp.fluid} />
                                            </div>
                                            <div className="member-info">
                                                <h5>Katerina Dydykina</h5>
                                                <span>{intl.formatMessage({ id:'AdministrationProperty' })}</span><br></br>
                                                <span>{intl.formatMessage({ id:'LegalDepartment' })}</span>
                                                <div className="member-contact">
                                                    <p><i className="fa fa-phone"></i>+34-922794214</p>
                                                    <p><i className="fa fa-envelope"></i>katerina@visaverde.com</p>
                                                    <div className="row inlineFlags">
                                                        <Img  className="col-2" fluid={data.lng_es.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_ru.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_en.childImageSharp.fluid} />
                                                        <a rel="noopener noreferrer"
                                                            href={withPrefix('/katja-certificate.pdf')}
                                                            target="_blank"
                                                        >
                                                            <Img  className="col-3" fluid={data.certificate.childImageSharp.fluid} />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-12">
                                        <div className="team-member">
                                            <div className="member-pic">
                                                <Img fluid={data.Anneke.childImageSharp.fluid} />
                                            </div>
                                            <div className="member-info">
                                                <h5>Anneke van Weelden</h5>
                                                <span>{intl.formatMessage({ id:'FrontOfficeCoordinator' })}</span><br></br>
                                                <span>{intl.formatMessage({ id:'MarketingAndCommunication' })}</span>
                                                <div className="member-contact">
                                                    <p><i className="fa fa-phone"></i>+34-610168751</p>
                                                    <p><i className="fa fa-envelope"></i>anneke@visaverde.com</p>
                                                    <div className="row inlineFlags">
                                                        <Img  className="col-2" fluid={data.lng_nl.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_es.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_en.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_de.childImageSharp.fluid} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-12">
                                        <div className="team-member">
                                            <div className="member-pic">
                                                <Img fluid={data.Ali.childImageSharp.fluid} />
                                            </div>
                                            <div className="member-info">
                                                <h5>Alina Varantsova</h5>
                                                <span>{intl.formatMessage({ id:'OfficeManagerSales' })}</span><br></br>
                                                <span>{intl.formatMessage({ id:'MarketingAndCommunication' })}</span>
                                                <br></br>
                                                <div className="member-contact">
                                                    <p><i className="fa fa-phone"></i>+34-610168751</p>
                                                    <p><i className="fa fa-envelope"></i>alina@visaverde.com</p>
                                                    <div className="row inlineFlags">
                                                        <Img  className="col-2" fluid={data.lng_es.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_en.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_ru.childImageSharp.fluid} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-sm-6 col-12">
                                        <div className="team-member">
                                            <div className="member-pic">
                                                <Img fluid={data.Emilia.childImageSharp.fluid} />
                                            </div>
                                            <div className="member-info">
                                                <h5>Emilia Kozioł</h5>
                                                <span>{intl.formatMessage({ id:'Sales' })}</span><br></br>
                                                <div className="member-contact">
                                                    <p><i className="fa fa-phone"></i>+34-666363631</p>
                                                    <p><i className="fa fa-envelope"></i>emilia@visaverde.com</p>
                                                    <div className="row inlineFlags">
                                                        <Img  className="col-2" fluid={data.lng_pl.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_en.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_es.childImageSharp.fluid} />
                                                        <a rel="noopener noreferrer"
                                                            href={withPrefix('/emilia-certificate.pdf')}
                                                            target="_blank"
                                                        >
                                                            <Img  className="col-3" fluid={data.certificate.childImageSharp.fluid} />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-12">
                                        <div className="team-member">
                                            <div className="member-pic">
                                                <Img fluid={data.Lennert.childImageSharp.fluid} />
                                            </div>
                                            <div className="member-info">
                                                <h5>Lennert De Wilde</h5>
                                                <span>{intl.formatMessage({ id:'Sales' })}</span>
                                                <div className="member-contact">
                                                    <p><i className="fa fa-phone"></i>+32-473491294</p>
                                                    <p><i className="fa fa-envelope"></i>lennert@visaverde.com</p>
                                                    <div className="row inlineFlags">
                                                        <Img  className="col-2" fluid={data.lng_en.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_es.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_nl.childImageSharp.fluid} />
                                                        <Img  className="col-2" fluid={data.lng_fr.childImageSharp.fluid} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="page-section">
                            <div className="container">
                                <div className="row about-text">
                                    <div className="col-xl-6 about-text-left">
                                        <h3 className="generic">{intl.formatMessage({ id:'About-us-left-title'})}</h3>
                                        <p className="generic">{intl.formatMessage({ id:'About-us-p-1'})}</p>
                                        <p className="generic">{intl.formatMessage({ id:'About-us-p-2'})}</p>
                                        <p className="generic">{intl.formatMessage({ id:'About-us-p-3'})}</p>
                                        <p className="generic">{intl.formatMessage({ id:'About-us-p-4'})}</p>
                                        <p 
                                            style={{
                                                textAlign: "justify",
                                                fontSize: "19px",
                                                color: "black",
                                                marginTop: "35px"
                                            }}
                                        >
                                            {intl.formatMessage({ id:'About-us-p-11'})}
                                        </p>
                                    </div>
                                    <div className="col-xl-6 about-text-right">
                                        <h3 className="generic">{intl.formatMessage({ id:'About-us-right-title'})}</h3>
                                        <h4><i className="fa fa-check-circle-o"></i>{intl.formatMessage({ id:'About-us-right-st-1'})}</h4>
                                        <p className="generic">{intl.formatMessage({ id:'About-us-p-5'})}</p>
                                        <h4><i className="fa fa-check-circle-o"></i>{intl.formatMessage({ id:'About-us-right-st-2'})}</h4>
                                        <p className="generic">{intl.formatMessage({ id:'About-us-p-6'})}</p>
                                        <h4><i className="fa fa-check-circle-o"></i>{intl.formatMessage({ id:'About-us-right-st-3'})}</h4>
                                        <p className="generic">{intl.formatMessage({ id:'About-us-p-7'})}</p>
                                        <h4><i className="fa fa-check-circle-o"></i>{intl.formatMessage({ id:'About-us-right-st-4'})}</h4>
                                        <p className="generic">
                                            {intl.formatMessage({ id:'About-us-p-8'})}<br/>
                                            {intl.formatMessage({ id:'About-us-p-9'})}<br/>
                                            {intl.formatMessage({ id:'About-us-p-10'})}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </section>
        </Layout>
        )

export default injectIntl(AboutPage)
export const query = graphql`
query {
    placeholderImage: file(relativePath: { eq: "page-top-bg.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    aboutImage: file(relativePath: { eq: "about.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
            }
        }
    },
    Mirjam: file(relativePath: { eq: "team/Mirjam.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    Connie: file(relativePath: { eq: "team/Connie.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    Katerina: file(relativePath: { eq: "team/Katerina_nueva.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    Ina: file(relativePath: { eq: "team/Ina.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    Ali: file(relativePath: { eq: "team/Ali.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    Emilia: file(relativePath: { eq: "team/Emilia.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    Arianna: file(relativePath: { eq: "team/Arianna.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    Anneke: file(relativePath: { eq: "team/anneke.jpeg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    Siloutte: file(relativePath: { eq: "team/Siloutte.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    Lennert: file(relativePath: { eq: "team/Lennert.jpeg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    Frank: file(relativePath: { eq: "team/frank.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    Luna: file(relativePath: { eq: "team/Luna.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    lng_es: file(relativePath: { eq: "flags/es.png" }) {
        childImageSharp {
            fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    lng_en: file(relativePath: { eq: "flags/en.png" }) {
        childImageSharp {
            fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    lng_de: file(relativePath: { eq: "flags/de.png" }) {
        childImageSharp {
            fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    lng_fr: file(relativePath: { eq: "flags/fr.png" }) {
        childImageSharp {
            fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    lng_it: file(relativePath: { eq: "flags/it.png" }) {
        childImageSharp {
            fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    lng_nl: file(relativePath: { eq: "flags/nl.png" }) {
        childImageSharp {
            fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    lng_pl: file(relativePath: { eq: "flags/pl.png" }) {
        childImageSharp {
            fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    lng_ru: file(relativePath: { eq: "flags/ru.png" }) {
        childImageSharp {
            fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    lng_esperanto: file(relativePath: { eq: "team/esperanto.png" }) {
        childImageSharp {
            fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    paw_print: file(relativePath: { eq: "team/paw-print.png" }) {
        childImageSharp {
            fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    certificate: file(relativePath: { eq: "team/certificate.png" }) {
        childImageSharp {
            fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
            }
        }
    }
}
`
